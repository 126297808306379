"use client";

import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { Icons } from "@repo/icons/lucide";
import { Button } from "@repo/ui/button";
import { Card, CardContent } from "@repo/ui/card";

export function Pricing({
  allowSubscription,
  subscribeAction,
}: {
  allowSubscription?: boolean;
  subscribeAction?: (plan: string) => Promise<void>;
}) {
  const posthog = usePostHog();

  const captureEvent = (
    eventName: string,
    properties?: Record<string, any>
  ) => {
    posthog?.capture(eventName, properties);
  };

  const handleSubscribe = async (plan: string) => {
    captureEvent("subscription_attempted", { plan });
    await subscribeAction?.(plan);
    captureEvent("subscription_completed", { plan });
  };

  return (
    <section
      id="pricing"
      className="container flex flex-col items-center gap-6 py-24 sm:gap-7"
    >
      <div className="flex flex-col gap-3">
        <span className="text-primary text-center font-bold uppercase">
          Pricing
        </span>
        <h2 className="font-heading text-center text-3xl font-semibold sm:text-4xl">
          Simple pricing
        </h2>
      </div>
      <p className="text-muted-foreground max-w-lg text-center text-lg">
        Pricing that fits your needs and helps you scale.
      </p>
      <div className="mt-7 grid w-full grid-cols-1 gap-7 lg:grid-cols-3">
        <Card className="relative">
          <CardContent className="divide-y p-0">
            <div className="flex flex-col items-center px-7 py-10">
              <h4 className="font-heading text-foreground text-2xl font-semibold">
                Solo
              </h4>
              <p className="text-muted-foreground mt-2">
                For the solo entrepreneur.
              </p>
              <div className="mt-5">
                <span className="font-heading text-5xl font-semibold">$70</span>
                <span className="text-sm"> /month</span>
              </div>
              {!allowSubscription ? (
                <Button
                  size="lg"
                  asChild
                  className="mt-10 w-full"
                  onClick={() =>
                    captureEvent("waitlist_button_clicked", { plan: "solo" })
                  }
                >
                  <Link href="#hero">Join Waitlist</Link>
                </Button>
              ) : (
                <Button
                  className="mt-10 w-full"
                  onClick={() => handleSubscribe("monthly-solo")}
                >
                  Subscribe
                </Button>
              )}
            </div>
            <ul className="space-y-2 px-7 py-10">
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  20 AI-powered FAQs
                </span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">1 Chatbot</span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">Unlimitied links</span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  1,000 conversations
                </span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  10 GB of file storage
                </span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  1 workspace, 1 user
                </span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  faqtory.io branding
                </span>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card className="border-primary relative border-2">
          <CardContent className="divide-y p-0">
            <div className="flex flex-col items-center px-7 py-10">
              <span className="bg-primary text-primary-foreground absolute inset-x-0 -top-5 mx-auto w-32 rounded-full px-3 py-2 text-center text-sm font-semibold shadow-md">
                Most popular
              </span>
              <h4 className="font-heading text-foreground text-2xl font-semibold">
                Pro
              </h4>
              <p className="text-muted-foreground mt-2">
                For startups and teams.
              </p>
              <div className="mt-5">
                <span className="font-heading text-5xl font-semibold">
                  $150
                </span>
                <span className="text-sm"> /month</span>
              </div>
              {!allowSubscription ? (
                <Button size="lg" asChild className="mt-10 w-full">
                  <Link href="#hero">Join Waitlist</Link>
                </Button>
              ) : (
                <Button
                  className="mt-10 w-full"
                  onClick={async () => {
                    await subscribeAction?.("monthly-pro");
                  }}
                >
                  Subscribe
                </Button>
              )}
            </div>
            <ul className="space-y-2 px-7 py-10">
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  Everything in Basic
                </span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  Unlimited FAQs and links
                </span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  Unlimited Chatbots
                </span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  10,000 conversations
                </span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  Collaborative inbox
                </span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  1 workspace, 5 users
                </span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  Integrations (available soon)
                </span>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card className="relative">
          <CardContent className="divide-y p-0">
            <div className="flex flex-col items-center px-7 py-10">
              <h4 className="font-heading text-foreground text-2xl font-semibold">
                Business
              </h4>
              <p className="text-muted-foreground mt-2">
                For large communication volumes. Starting at:
              </p>
              <div className="mt-5">
                <span className="font-heading text-5xl font-semibold">
                  $500
                </span>
                <span className="text-sm"> /month</span>
              </div>
              {!allowSubscription ? (
                <Button size="lg" asChild className="mt-10 w-full">
                  <Link href="#hero">Join Waitlist</Link>
                </Button>
              ) : (
                <Button
                  className="mt-10 w-full"
                  onClick={async () => {
                    await subscribeAction?.("monthly-business");
                  }}
                >
                  Subscribe
                </Button>
              )}
            </div>
            <ul className="space-y-2 px-7 py-10">
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">Everything in Pro</span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  Custom domain &amp; branding
                </span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  Single sign-on (SSO)
                </span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">Custom SLA</span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">
                  Custom integrations
                </span>
              </li>
              <li className="flex items-center gap-3">
                <Icons.check size={24} className="text-primary" />
                <span className="text-muted-foreground">Custom reporting</span>
              </li>
            </ul>
          </CardContent>
        </Card>
      </div>
    </section>
  );
}
